import { Link } from '@remix-run/react';
import { cn } from '@cardo/lib';
import type { DataWrapped, StrapiImage, Tag } from '@cardo/types';
import { IoReader } from 'react-icons/io5';
import { generateImgProps, getAlt } from '~/lib/strapiImage';

interface BlogCardProps {
  title: string;
  subtitle: string | null;
  tags: DataWrapped<Tag[]> | null;
  featuredImage: StrapiImage | null;
  thumbnailImage: StrapiImage | null;
  slug: string;
  blocks?: unknown[];
  className?: string;
  featured?: boolean;
}

export default function BlogCard({
  title,
  subtitle,
  tags,
  featuredImage,
  thumbnailImage,
  slug,
  className,
  featured,
}: BlogCardProps) {
  const image = thumbnailImage || featuredImage;

  return (
    <Link
      to={`/articles/${slug}`}
      className={cn(
        'group flex border rounded-lg shadow-lg overflow-hidden text-theme-blue-darkest no-underline hover:scale-105 transition-transform',
        featured ? 'flex-col sm:flex-row sm:items-center sm:h-80' : 'flex-col',
        className
      )}
      prefetch="intent"
    >
      <div
        className={cn(
          'relative w-full bg-white overflow-hidden rounded-t-lg py-3',
          featured && 'sm:flex-1 h-full',
          !featured && 'h-48'
        )}
      >
        {image?.data && (
          <img
            {...generateImgProps(image)}
            alt={getAlt(image)}
            className={cn(
              'scale-105 group-hover:scale-100 transition-transform object-contain',
              !featured && 'h-full w-full',
              featured && 'h-full w-3/5 mx-auto'
            )}
          />
        )}
        {!image ||
          (!image?.data && (
            <IoReader className="text-7xl text-theme-blue-darkest mx-auto h-full my-auto" />
          ))}
      </div>
      <div
        className={cn(
          'flex flex-col flex-grow gap-4 p-5 bg-gray-200 group-hover:bg-white',
          featured && 'sm:flex-1 sm:py-16 h-full justify-center'
        )}
      >
        <div className="flex flex-col gap-1">
          <p className="text-lg font-semibold text-theme-blue-darkest">
            {title}
          </p>
          <p className="text-sm">{subtitle}</p>
        </div>
        {tags?.data && tags.data?.length > 0 && (
          <div className="text-xs flex flex-row items-center gap-0.5 text-gray-500">
            {tags.data.map((tag) => (
              <div
                className="py-1 px-2 border rounded-md bg-gray-300"
                key={tag.id}
              >
                {tag.attributes.name}
              </div>
            ))}
          </div>
        )}
      </div>
    </Link>
  );
}
